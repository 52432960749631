<template>
  <div class="partnership-wrap">
    <!-- banner -->
    <div class="banner-common" :class="{ 'banner-pc-common': isPc }">
      <img src="../../assets/img/hezuo.png" v-if="!isPc" alt="" />
      <img src="../../assets/img-pc/hezuo-pc.png" v-else alt="" />
      <div class="text">
        <span class="chanese">合作加盟</span>
        <span class="english">Cooperation and Alliance</span>
      </div>
    </div>
    <!-- 扶持 -->
    <div class="support" v-if="!isPc">
      <titleModule :titleInfo="supportInfo" />
      <div class="img-wrap">
        <img src="../../assets/img/fuchi.png" alt="" />
      </div>
    </div>

    <div class="support-pc" v-else>
      <supportInfo :isPc="isPc" />
    </div>

    <!-- 优势 -->
    <div class="advantages" :class="{ 'advantages-pc': isPc }">
      <titleModule :titleInfo="advantagesInfo" :isPc="isPc" />
      <div class="advantages-tag">
        操作安全 趣味无穷
      </div>
      <div class="advantages-img" v-if="!isPc">
        <img src="../../assets/img/advantages-1.png" alt="" />
        <img src="../../assets/img/advantages-2.png" alt="" />
        <img src="../../assets/img/advantages-3.png" alt="" />
        <img src="../../assets/img/advantages-4.png" alt="" />
      </div>
      <div class="advantages-img" v-else>
        <img src="../../assets/img-pc/advantages-1.png" alt="" />
        <img src="../../assets/img-pc/advantages-2.png" alt="" />
        <img src="../../assets/img-pc/advantages-3.png" alt="" />
        <img src="../../assets/img-pc/advantages-4.png" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
import titleModule from "../../components/title";
import supportInfo from "../index/components/supportInfo";
export default {
  name: "partners-ship",
  props: ["isPc"],
  components: {
    titleModule,
    supportInfo
  },
  data() {
    return {
      supportInfo: {
        titleRed: "保姆式",
        titleWhite: "落地扶持",
        remark: "NANNY STYLE LANDING SUPPORT"
      },
      advantagesInfo: {
        titleRed: "4大",
        titleWhite: "核心生产优势",
        remark: "FOUR CORE PRODUCTION ADVANTAGES"
      }
    };
  },

  methods: {},

  created() {}
};
</script>

<style lang="less" scoped>
@import "./partnerShip.less";
.partnership-wrap {
  background-color: #f7f7f7;
  .support {
    background-color: #fff;
    padding: 48px 0 60px;
    border-radius: 20px;
    .img-wrap {
      margin-top: 33px;
      text-align: center;
      img {
        width: 912px;
      }
    }
  }
  .advantages {
    background-color: #fff;
    margin-top: 21px;
    padding: 60px 48px 78px 47px;
    border-radius: 20px;
    .advantages-tag {
      margin: 50px auto;
      width: 455px;
      height: 92px;
      background-image: url("../../assets/img/advantages-tag.png");
      background-size: 455px;
      text-align: center;
      line-height: 92px;
      font-size: 30px;
      font-weight: bold;
      letter-spacing: 2px;
      color: #d1102e;
    }
    .advantages-img {
      img {
        margin-bottom: ;
      }
    }
  }
}
</style>

<template>
  <!-- <div class="title" :class="{ 'module-title-pc': isPc }">
    <span class="left-hr"></span>
    <span class="text" :class="{ 'no-bold': titleInfo.noBold }"
      >{{ titleInfo.titleRed | strFilter
      }}<i
        class="jianjie"
        :class="{ 'text-f': titleInfo.titleWhite === '电子花炮' }"
        >{{ titleInfo.titleWhite | strFilter }}</i
      ></span
    >
    <span class="right-hr"></span>
    <span class="text-remark">{{ titleInfo.remark | strFilter }}</span>
  </div> -->
  <div class="title" :class="{ 'module-title-pc': isPc }">
    <span class="title-text">
      <span class="color-red">{{ titleInfo.titleRed | strFilter }}</span>
      <span class="color-black">{{ titleInfo.titleWhite | strFilter }}</span>
    </span>
    <!-- <span class="text-eg">{{ titleInfo.remark | strFilter }}</span> -->
  </div>
</template>

<script>
export default {
  props: ["titleInfo", "isPc"],
  data() {
    return {};
  },
  filters: {
    strFilter(val) {
      return val || "";
    }
  },

  methods: {},

  created() {}
};
</script>

<style lang="less" scoped>
.title {
  text-align: center;
  height: 60px;
  font-size: 30px;
  font-weight: bold;
  letter-spacing: 3px;
  position: relative;
  &::after {
    content: "";
    width: 40px;
    height: 6px;
    background-image: linear-gradient(
        93deg,
        #004dff 0%,
        #00a4fe 37%,
        #00fbfd 100%
      ),
      linear-gradient(#4287fc, #4287fc);
    background-blend-mode: normal, normal;
    position: absolute;
    bottom: -10px;
    left: 50%;
    transform: translateX(-50%);
  }
}
</style>

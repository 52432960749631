<template>
  <div>
    <div class="support-wrap" v-if="!isPc">
      <title-module :titleInfo="titleInfo" />
      <div class="content">
        <img src="../../../assets/img/fuchi.png" alt="" />
      </div>
    </div>
    <div class="support-wrap-pc" v-else>
      <title-module
        :titleInfo="titleInfo"
        :isPc="isPc"
        class="support-wrap-pc-title"
      />
      <div class="remark">
        全面体系保障 · 加盟商赚钱无忧
      </div>
      <div class="content-wrap">
        <img src="../../../assets/img-pc/fuchi.png" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
import titleModule from "@/components/title";
export default {
  props: ["isPc"],
  components: {
    titleModule
  },
  data() {
    return {
      titleInfo: {
        titleRed: "保姆式",
        titleWhite: "落地扶持",
        remark: "NANNY STYLE LANDING SUPPORT"
      }
    };
  },

  methods: {},

  created() {}
};
</script>

<style lang="less" scoped>
.support-wrap {
  padding: 48px 0 60px;
  background-color: #ffffff;
  box-shadow: 0px 3px 10px 0px rgba(191, 206, 222, 0.2);
  border-radius: 20px;
  .content {
    width: 100%;
    margin-top: 30px;
    text-align: center;
    img {
      width: 912px;
      height: 617px;
    }
  }
}
</style>
